interface ShopMapProps {
  address: string
}

export default function ShopMap({ address }: ShopMapProps) {
  console.warn(encodeURI(address))
  return (
    <div className="shop-map-container">
      <iframe
        className="shop-map"
        title="Mappa negozio"
        referrerPolicy="no-referrer-when-downgrade"
        src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyAmPuwmolTjx5-4PUlcnUj5P13w_j9ef10&q=${encodeURI(address)}`}
        allowFullScreen
      ></iframe>
    </div>
  )
}
