import { useEffect, useState } from "react"
import { getShops } from "../../api/shopsApi"
import { ShopDto } from "../../models/api/shopApiTypes"
import ShopCard from "../common/ShopCard"
import { TownDto } from "../../models/api/districtsApiTypes"
import { CategoryDto } from "../../models/api/categoriesApiTypes"
import { getDistricts } from "../../api/districtsApi"
import { getCategories } from "../../api/categoriesApi"
import ShopsFilter from "./ShopsFilter"

export default function ShopsList() {
  const SHOPS_PER_PAGE = 12
  const [loading, setLoading] = useState(true)
  const [shops, setShops] = useState<ShopDto[]>([])
  const [page, setPage] = useState(1)
  const [totalShopsCount, setTotalShopsCount] = useState<number>()
  const totalPages = totalShopsCount !== undefined ? Math.ceil(totalShopsCount / SHOPS_PER_PAGE) : 0
  const [towns, setTowns] = useState<TownDto[]>([])
  const [categories, setCategories] = useState<CategoryDto[]>([])
  const [townsFilter, setTownsFilter] = useState<string[]>([])
  const [categoriesFilter, setCategoriesFilter] = useState<string[]>([])

  useEffect(() => {
    // Reset page when filters change
    setPage(1)
    getShops({ page: 1, pageSize: SHOPS_PER_PAGE, categories: categoriesFilter, towns: townsFilter, randomize: false })
      .then((result) => {
        setShops(result.shops)
        setTotalShopsCount(result.totalCount)
        setLoading(false)
      })
      .catch(() => console.log("Error loading shops"))
  }, [categoriesFilter, townsFilter])

  useEffect(() => {
    getDistricts().then((districts) => {
      setTowns(districts.map((d) => d.towns).flat())
    })
    getCategories().then((c) => setCategories(c))
  }, [])

  const loadMore = () => {
    if (!loading) {
      getShops({
        page: page + 1,
        pageSize: SHOPS_PER_PAGE,
        categories: categoriesFilter,
        towns: townsFilter,
        randomize: false,
      })
        .then((result) => {
          setPage((p) => p + 1)
          setShops((s) => (s !== undefined ? s.concat(result.shops) : result.shops))
          setTotalShopsCount(result.totalCount)
          setLoading(false)
        })
        .catch(() => console.log("Error loading shops"))
    }
  }

  return (
    <>
      <div className="row mx-auto mb-2" style={{ maxWidth: "1440px" }}>
        <div className="d-flex">
          <div className="ms-2 mt-2">
            {totalShopsCount !== undefined && totalShopsCount > 0 ? <h5>{totalShopsCount} attivit&agrave;</h5> : null}
          </div>
          <div className="ms-auto">
            {towns !== undefined && categories !== undefined ? (
              <ShopsFilter
                towns={towns}
                categories={categories}
                townsFilter={townsFilter}
                setTownsFilter={setTownsFilter}
                categoriesFilter={categoriesFilter}
                setCategoriesFilter={setCategoriesFilter}
              />
            ) : null}
          </div>
        </div>
      </div>
      <div className="row mx-auto" style={{ maxWidth: "1440px" }}>
        {shops.length > 0 ? (
          shops.map((shop) => (
            <div className="col-12 col-md-6 col-lg-4 col-xl-3 mt-3 mb-3" key={shop.id}>
              <ShopCard
                shopId={shop.id}
                shopImg={shop.photos}
                shopLogo={shop.company.logo}
                shopTitle={shop.name}
                shopDescription={shop.description}
                shopCategory={shop.category}
                shopLocation={shop.address}
                shopPhone={shop.phoneNumber}
                shopWebsite={shop.website}
                shopTown={shop.town}
              />
            </div>
          ))
        ) : !loading ? (
          <div className="col-12 text-center mt-4 mb-4">
            <h1>Nessun negozio trovato</h1>
            <p className="main-text">Prova a cambiare i filtri impostati</p>
          </div>
        ) : (
          <div className="col-12 text-center mt-4 mb-4">
            <h4>
              <img
                src={`${process.env.PUBLIC_URL}/img/icons/refresh.svg`}
                alt="Caricamento"
                className="image-loading me-2 mb-1"
              />
              Caricamento&hellip;
            </h4>
          </div>
        )}
      </div>
      {totalPages !== undefined && page < totalPages ? (
        <div className="mx-auto text-center">
          <button className="btn-green-outline" onClick={loadMore}>
            {loading ? (
              <img
                src={`${process.env.PUBLIC_URL}/img/icons/refresh.svg`}
                alt="Caricamento"
                className="image-loading me-2"
              />
            ) : null}
            Carica altri
          </button>
        </div>
      ) : null}
    </>
  )
}
