import TextBoxWithIcon from "../textbox/TextBoxWithIcon"
import { Link } from "react-router-dom"

export default function HomeForShopsSection() {
  return (
    <div className="row p-4">
      <div className="row align-items-center pt-4 home-subsection-main-container">
        <div className="col-4 p-4 text-center d-none d-lg-block">
          <img
            src={`${process.env.PUBLIC_URL}/img/home/home_why_section_image.png`}
            alt="Payment"
            style={{ width: "100%" }}
          />
        </div>
        <div className="col-12 col-lg-8 home-subsection-text-container">
          <p className="home-subsection-title">hai un&apos;attività</p>
          <h3 className="home-subsection-subtitle">Negoziante o libero professionista</h3>
          <p className="home-subsection-description mb-4">Voglio iscrivermi a Buoni Sul Serio, come posso fare?</p>
          <div className="row mt-4 pt-4">
            <div className="col">
              <TextBoxWithIcon
                iconPath={`${process.env.PUBLIC_URL}/img/home/subscription.svg`}
                title="Iscrizione semplificata"
                text="Con una semplice iscrizione, sarai da subito immerso nel mondo Buoni Sul Serio."
              />
            </div>
            <div className="col">
              <TextBoxWithIcon
                iconPath={`${process.env.PUBLIC_URL}/img/home/bss_platform.svg`}
                title="Una piattaforma su misura"
                text="Sulla web app di Buoni Sul Serio troverai uno spazio interamente dedicato a te. Potrai aggiungere le foto e le informazioni principali della tua attivit&agrave;. Così facendo sarai visibile nella lista commercianti che aderiscono a Buoni sul Serio."
              />
            </div>
          </div>
          <div className="row mt-4 pt-4">
            <div className="col">
              <TextBoxWithIcon
                iconPath={`${process.env.PUBLIC_URL}/img/home/mobile.svg`}
                title="Tutto sotto controllo"
                text="Con l'app tieni sempre sotto controllo l'andamento delle tue vendite/incassi tramite Gift Card."
              />
            </div>
            <div className="col">
              <TextBoxWithIcon
                iconPath={`${process.env.PUBLIC_URL}/img/home/checkout.svg`}
                title="Riscuoti direttamente dall'app"
                text="Come incasso i miei guadagni? Niente mail o richieste esterne, potrai riscuotere i tuoi guadagni direttamente dall'app e in pochissimo tempo."
              />
            </div>
          </div>
          <div className="row text-center p-4 m-4">
            <div className="col">
              <Link to="/registrazione">
                <button className="btn btn-violet">Iscrivi la tua attivit&agrave; a Buoni sul Serio</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
