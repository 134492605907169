import { GetFaqResultDto } from "../models/api/filesApiTypes"
import { api } from "./clientApi"

export function getFaq() {
  return new Promise<GetFaqResultDto>((resolve, reject) => {
    api
      .get("/Files/faq")
      .then((data) => resolve(data.data))
      .catch((err) => reject(err))
  })
}
