import { Link } from "react-router-dom"

interface HomeMainContainerProps {
  displayButtons?: boolean
}

export default function HomeMainContainer({ displayButtons = true }: Readonly<HomeMainContainerProps>) {
  return (
    <div className="row mb-4 pb-4">
      <div className="col-lg-3 d-none d-lg-block">
        <div id="home-gift-logo-container-left">
          <img
            src={`${process.env.PUBLIC_URL}/img/home/home_gift_icon_left.png`}
            alt="Gift logo"
            id="home-gift-logo-left"
          />
        </div>
      </div>
      <div className="col-12 col-lg-6">
        <h1 id="home-main-title">La piattaforma per incentivare il commercio locale</h1>
        <p id="home-main-text">
          Buoni Sul Serio &egrave; la nuova piattaforma che permette di regalare gift card utilizzabili nei negozi
          locali del Distretto del Commercio Alta Val Seriana - Clusone. Puoi spendere la tua gift card in tutte le
          attivit&agrave; aderenti alla piattaforma, fino all&apos;esaurimento del suo valore!
        </p>
        {displayButtons ? (
          <div className="d-flex justify-content-center text-center" id="home-main-buttons-container">
            <div className="ms-4 me-4">
              <a href="#tutorial">
                <button type="button" className="btn btn-green">
                  Acquista una gift card
                </button>
              </a>
            </div>
            <div className="ms-4 me-4">
              <Link to="/negozi">
                <button type="button" className="btn btn-green-outline">
                  Scopri le attivit&agrave; aderenti
                </button>
              </Link>
            </div>
          </div>
        ) : null}
      </div>
      <div className="col-lg-3 d-none d-lg-block">
        <div id="home-gift-logo-container-right">
          <img
            src={`${process.env.PUBLIC_URL}/img/home/home_gift_icon_right.png`}
            alt="Gift logo right"
            id="home-gift-logo-right"
          />
        </div>
      </div>
    </div>
  )
}
