import cn from "classnames"
import ShopCardImageCarousel from "./ShopCardImageCarousel"
import "../../assets/css/shopcard.css"
import { useNavigate } from "react-router-dom"

interface ShopCardProps {
  shopId: string
  shopImg: string[]
  shopLogo: string
  shopTitle: string
  shopDescription: string
  shopCategory: string
  shopLocation: string
  shopPhone: string
  shopWebsite: string
  shopTown: string
}

export default function ShopCard({
  shopId,
  shopImg,
  shopLogo,
  shopTitle,
  shopCategory,
  shopLocation,
  shopPhone,
  shopWebsite,
  shopTown,
}: Readonly<ShopCardProps>) {
  const navigate = useNavigate()

  return (
    <div className="shop-card mx-auto" onClick={() => navigate(`/negozio/${shopId}`)}>
      <div className="shop-card-img mt-3" onClick={(event) => event.stopPropagation()}>
        <div
          className="shop-card-logo"
          style={{
            background: `url("${shopLogo}")`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
          onClick={() => navigate(`/negozio/${shopId}`)}
        ></div>
        <ShopCardImageCarousel img={shopImg} shopId={shopId} />
      </div>
      <p className="shop-card-town ms-4 me-4 mt-3 mb-1">{shopTown}</p>
      <p className="shop-card-title ms-4 me-4 mt-0 mb-2">{shopTitle}</p>
      <div className="ms-4 shop-card-tags-container">
        <div className="shop-card-category">{shopCategory}</div>
      </div>

      <div className="d-flex me-4 mb-4 shop-card-icons-container">
        {shopLocation !== null ? (
          <div className="ms-auto shop-card-icon ps-4">
            <a href={`http://maps.google.com/?q=${shopLocation}`} target="_blank" rel="noreferrer">
              <img src={`${process.env.PUBLIC_URL}/img/icons/location.svg`} alt="Location" style={{ width: "24px" }} />
            </a>
          </div>
        ) : null}
        {shopPhone !== null ? (
          <div className={cn("ms-4 ps-4 shop-card-icon", { "ms-auto": shopLocation === undefined })}>
            <a href={`tel:${shopPhone}`}>
              <img src={`${process.env.PUBLIC_URL}/img/icons/phone.svg`} alt="Phone" style={{ width: "24px" }} />
            </a>
          </div>
        ) : null}
        {shopWebsite !== null ? (
          <div
            className={cn("ms-4 ps-4 shop-card-icon", {
              "ms-auto": shopLocation === undefined && shopPhone === undefined,
            })}
          >
            <a href={shopWebsite} target="_blank" rel="noreferrer">
              <img src={`${process.env.PUBLIC_URL}/img/icons/website.svg`} alt="Website" style={{ width: "24px" }} />
            </a>
          </div>
        ) : null}
      </div>
    </div>
  )
}
