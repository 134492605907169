import { useNavigate } from "react-router-dom"
import { GetShopByIdResultDto } from "../../models/api/shopApiTypes"
import ShopDetailsImageCarousel from "./ShopDetailsImageCarousel"
import ShopMap from "./ShopMap"
import ShopsRelated from "./ShopsRelated"

interface ShopDetailsProps {
  shop: GetShopByIdResultDto
}

export default function ShopDetails({ shop }: Readonly<ShopDetailsProps>) {
  const navigate = useNavigate()

  return (
    <div className="row">
      <div className="p-4 shop-page-container">
        <div className="container pt-4 pb-4">
          <h1>
            <img
              src={`${process.env.PUBLIC_URL}/img/icons/back.svg`}
              alt="Indietro"
              style={{ width: "2rem", paddingBottom: "0.25rem", cursor: "pointer" }}
              onClick={() => navigate(-1)}
            />{" "}
            {shop.name}
          </h1>
          <ShopDetailsImageCarousel img={shop.photos} />
          {shop.description !== null && shop.description.length > 0 ? (
            <p className="mt-4 mb-4">{shop.description}</p>
          ) : null}
          {shop.address !== null && shop.address.length > 0 ? (
            <>
              <p className="mt-5">
                <b>Indirizzo</b>
              </p>
              <p>{shop.address}</p>
              <ShopMap address={shop.address} />
            </>
          ) : null}
          {shop.related.length > 0 ? <ShopsRelated related={shop.related} /> : null}
        </div>
      </div>
    </div>
  )
}
