import { useEffect, useState } from "react"
import ShopCard from "../common/ShopCard"
import { ShopDto } from "../../models/api/shopApiTypes"
import { getShops } from "../../api/shopsApi"
import cn from "classnames"
import HomeShopCardsSkeleton from "./HomeShopCardsSkeleton"
import { Link } from "react-router-dom"

export default function HomeShopsList() {
  const CARD_NUMBER = 3
  const [shops, setShops] = useState<ShopDto[]>()

  useEffect(() => {
    getShops({ page: 1, pageSize: CARD_NUMBER, randomize: true, completed: true })
      .then((result) => setShops(result.shops))
      .catch(() => console.log("Error loading shops"))
  }, [])

  return (
    <div className="row p-4 home-white-container">
      <div className="row  pt-4 home-shops-container">
        <div className="col-12 col-lg-3 home-subsection-text-container">
          <p className="home-subsection-title">TUTTE LE ATTIVIT&Agrave;</p>
          <h3 className="home-subsection-subtitle">In ogni paese, per tutti i gusti</h3>
          <p className="home-subsection-description mb-4">
            Buoni Sul Serio &egrave; attivo in tutti i paesi del distretto e in costante aggiornamento.
          </p>
          <Link to="/negozi">
            <button className="btn btn-violet">Scopri tutte le attivit&agrave;</button>
          </Link>
        </div>
        <div className="col-12 col-lg-9 mt-4 mt-lg-0 d-none d-sm-block">
          <div className="row">
            {shops !== undefined ? (
              shops.slice(0, CARD_NUMBER).map((shop, index) => (
                <div className={cn("col-6 col-xl-4", { "d-none d-xl-block": index > 1 })} key={shop.id}>
                  <ShopCard
                    shopId={shop.id}
                    shopImg={shop.photos}
                    shopLogo={shop.company.logo}
                    shopTitle={shop.name}
                    shopDescription={shop.description}
                    shopCategory={shop.category}
                    shopLocation={shop.address}
                    shopPhone={shop.phoneNumber}
                    shopWebsite={shop.website}
                    shopTown={shop.town}
                  />
                </div>
              ))
            ) : (
              <HomeShopCardsSkeleton />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
