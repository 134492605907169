import { ShopDto } from "../../models/api/shopApiTypes"
import ShopCard from "../common/ShopCard"

interface ShopsRelatedProps {
  related: ShopDto[]
}

export default function ShopsRelated({ related }: Readonly<ShopsRelatedProps>) {
  return (
    <>
      <p className="mt-5">
        <b>Altre localit&agrave; di questa attivit&agrave;</b>
      </p>
      <div className="row mx-auto" style={{ maxWidth: "1440px" }}>
        {related.map((shop) => (
          <div className="col-12 col-md-6 col-lg-4 col-xl-3 mt-3 mb-3" key={shop.id}>
            <ShopCard
              shopId={shop.id}
              shopImg={shop.photos}
              shopLogo={shop.company.logo}
              shopTitle={shop.name}
              shopDescription={shop.description}
              shopCategory={shop.category}
              shopLocation={shop.address}
              shopPhone={shop.phoneNumber}
              shopWebsite={shop.website}
              shopTown={shop.town}
            />
          </div>
        ))}
      </div>
    </>
  )
}
