import { useSearchParams } from "react-router-dom"
import HeaderLogo from "../components/headers/HeaderLogo"
import HomeForShopsSection from "../components/home/HomeForShopsSection"
import HomeTutorialSection from "../components/home/HomeTutorialSection"
import Footer from "../components/common/Footer"
import HomeShopsList from "../components/home/HomeShopsList"
import HomeMainContainer from "../components/home/HomeMainContainer"
import HomeRegistrationMsg from "../components/home/HomeRegistrationMsg"
import "../assets/css/home.css"

export default function Home() {
  const [searchParams] = useSearchParams()
  const registration = searchParams.get("registration")

  return (
    <>
      <HomeRegistrationMsg registration={registration} />
      <HeaderLogo />
      <HomeMainContainer />
      <HomeShopsList />
      <HomeForShopsSection />
      <HomeTutorialSection />
      <Footer />
    </>
  )
}
