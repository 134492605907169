import { ArrowProps } from "react-multi-carousel"
import React from "react"
import { ReactComponent as PrevIcon } from "../common/carousel_left.svg"

export const MultiCarouselLeftArrow: React.FC<ArrowProps> = ({ onClick, ...rest }) => {
  return (
    <button onClick={() => onClick?.()} {...rest} className="multi-carousel-left-arrow">
      <PrevIcon className="multi-carousel-icon" />
    </button>
  )
}
