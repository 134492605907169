import RegistrationRequestForm from "../components/form/RegistrationRequestForm"
import RegistrationPageHeader from "../components/headers/RegistrationPageHeader"
import "../assets/css/registration.css"
import { Link } from "react-router-dom"

export default function RegistrationPage() {
  return (
    <div
      style={{ backgroundColor: "#FFF", maxWidth: "850px" }}
      className="mt-0 mb-0 mt-md-4 mb-md-4 rounded-4 mx-auto p-4"
    >
      <div className="text-end">
        <Link to="/">
          <img src={`${process.env.PUBLIC_URL}/img/icons/close.svg`} alt="Close" />
        </Link>
      </div>
      <RegistrationPageHeader />
      <RegistrationRequestForm />
    </div>
  )
}
