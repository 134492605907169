import { useEffect, useState } from "react"
import { Modal } from "react-bootstrap"

interface HomeRegistrationMsgProps {
  registration: string | null
}

export default function HomeRegistrationMsg({ registration }: HomeRegistrationMsgProps) {
  const [showRegistrationMsg, setShowRegistrationMsg] = useState(false)

  useEffect(() => {
    if (registration === "true") setShowRegistrationMsg(true)
  }, [registration])

  return (
    <>
      {registration === "true" ? (
        <Modal show={showRegistrationMsg} onHide={() => setShowRegistrationMsg(false)} centered>
          <Modal.Header>
            <Modal.Title>Registrazione inviata 🎉</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div style={{ marginBottom: "10px" }}>
              Complementi! Hai appena inviato la tua richiesta di registrazione a Buoni Sul Serio. Un amministratore
              della piattaforma approverà la tua richiesta e riceverai nelle prossime ore una email per accedere alla
              tua Dashboard privata, aggiornare la password e completare il tuo profilo.
            </div>
            <small>Controlla anche nella spam</small>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="submit"
              className="btn btn-violet fw-bold rounded-3 ps-4 pe-4"
              onClick={() => setShowRegistrationMsg(false)}
            >
              Chiudi
            </button>
          </Modal.Footer>
        </Modal>
      ) : null}
    </>
  )
}
