import { Carousel, CarouselItem } from "react-bootstrap"
import { ReactComponent as PrevIcon } from "./carousel_left.svg"
import { ReactComponent as NextIcon } from "./carousel_right.svg"
import { Link } from "react-router-dom"

interface ShopCardImageCarouselProps {
  img: string[]
  shopId: string
}

export default function ShopCardImageCarousel({ img, shopId }: ShopCardImageCarouselProps) {
  return (
    <Link to={`/negozio/${shopId}`}>
      {img.length > 1 ? (
        <Carousel
          className="shop-card-img-container"
          interval={null}
          prevIcon={<PrevIcon className="custom-carousel-icon" />}
          nextIcon={<NextIcon className="custom-carousel-icon" />}
        >
          {img.map((imgSrc, index) => (
            <CarouselItem className="w-100 h-100" key={index}>
              <img src={imgSrc} className="shop-card-img object-fit-cover" alt="Immagine negozio" />
            </CarouselItem>
          ))}
        </Carousel>
      ) : img.length > 0 ? (
        <div className="shop-card-img-container">
          <img src={img[0]} className="w-100 h-100 object-fit-cover" alt="Immagine negozio" />
        </div>
      ) : (
        <div className="shop-card-img-container">
          <img
            src={`${process.env.PUBLIC_URL}/img/shop/noimg.png`}
            className="w-100 h-100 object-fit-cover"
            alt="Immagine negozio"
          />
        </div>
      )}
    </Link>
  )
}
