import { Accordion } from "react-bootstrap"
import Footer from "../components/common/Footer"
import HeaderLogo from "../components/headers/HeaderLogo"
import HomeMainContainer from "../components/home/HomeMainContainer"
import { GetFaqResultDto } from "../models/api/filesApiTypes"
import { useEffect, useState } from "react"
import "../assets/css/accordion.css"
import { getFaq } from "../api/filesApi"

export default function FaqPage() {
  const [faq, setFaq] = useState<GetFaqResultDto>()

  useEffect(() => {
    getFaq()
      .then((data) => setFaq(data))
      .catch(() => console.error("Cannot load FAQ"))
  }, [])

  return (
    <>
      <HeaderLogo />
      <HomeMainContainer displayButtons={false} />
      <div className="container mt-4 pt-4">
        <Accordion defaultActiveKey="0" alwaysOpen>
          {faq !== undefined
            ? faq.map((item, index) => (
                <Accordion.Item eventKey={index.toString()} key={index}>
                  <Accordion.Header>{item.title}</Accordion.Header>
                  <Accordion.Body>{item.description}</Accordion.Body>
                </Accordion.Item>
              ))
            : null}
        </Accordion>
      </div>
      <Footer />
    </>
  )
}
