export default function Footer() {
  return (
    <footer>
      <div className="row p-4">
        <div className="col-12 col-lg-6 text-center text-lg-start">
          <div className="align-self-center footer-left-item">&copy;2024 BuoniSulSerio</div>
        </div>
      </div>
      <div className="row">
        <div style={{ paddingLeft: "100px", paddingRight: "100px" }}>
          <hr />
        </div>
      </div>
      <div className="row p-4">
        <div className="col-12 col-lg-6 text-center text-lg-start">
          <div className="d-inline-flex">
            <div className="align-self-center footer-left-item me-lg-4">
              <img
                src={`${process.env.PUBLIC_URL}/img/footer/icons/logo_distretto.png`}
                alt="Logo distretto"
                style={{ width: "96px" }}
              />
            </div>
            <div className="d-none d-lg-block" style={{ fontSize: "13px" }}>
              Associazione del distretto del commercio Alta Valle Seriana
              <br />
              Piazza Sant&apos;Andrea 1 - 24023 - Clusone (BG)
              <br />
              P.IVA 03928980162 - C.F. 03928980162
              <br />
              Telefono 0346 72220
              <br />
              E-mail distrettodelcommercioaltavalle@gmail.com
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-6 mt-4 mt-lg-0 text-center text-lg-end">
          <div style={{ marginLeft: "100px", marginRight: "100px" }}>
            <div className="d-inline-flex">
              <div className="me-4">
                <a href="https://link.buonisulserio.it/go/social/fb?source=webapp">
                  <img src={`${process.env.PUBLIC_URL}/img/footer/icons/facebook.svg`} alt="Facebook" />
                </a>
              </div>
              <div className="me-4">
                <a href="https://link.buonisulserio.it/go/social/yt?source=webapp">
                  <img src={`${process.env.PUBLIC_URL}/img/footer/icons/youtube.svg`} alt="Youtube" />
                </a>
              </div>
              <div>
                <a href="https://link.buonisulserio.it/go/social/ig?source=webapp">
                  <img src={`${process.env.PUBLIC_URL}/img/footer/icons/instagram.svg`} alt="Instagram" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}
