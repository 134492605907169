import { ArrowProps } from "react-multi-carousel"
import React from "react"
import { ReactComponent as NextIcon } from "../common/carousel_right.svg"

export const MultiCarouselRightArrow: React.FC<ArrowProps> = ({ onClick, ...rest }) => {
  return (
    <button onClick={() => onClick?.()} {...rest} className="multi-carousel-right-arrow">
      <NextIcon className="multi-carousel-icon" />
    </button>
  )
}
