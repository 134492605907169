import { Route, Routes } from "react-router-dom"
import Home from "./pages/Home"
import RegistrationPage from "./pages/RegistrationPage"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import "react-loading-skeleton/dist/skeleton.css"
import ShopListPage from "./pages/ShopListPage"
import "bootstrap/dist/js/bootstrap.bundle"
import FaqPage from "./pages/FaqPage"
import ShopPage from "./pages/ShopPage"

function App() {
  return (
    <div className="App">
      <div className="container-fluid">
        <Routes>
          <Route path="/negozi" element={<ShopListPage />} />
          <Route path="/registrazione" element={<RegistrationPage />} />
          <Route path="/domande-frequenti" element={<FaqPage />} />
          <Route path="/negozio/:shopSlug" element={<ShopPage />} />
          <Route path="*" element={<Home />} />
        </Routes>
      </div>
      <ToastContainer />
    </div>
  )
}

export default App
