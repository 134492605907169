import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import { MultiCarouselLeftArrow } from "./MultiCarouselLeftArrow"
import { MultiCarouselRightArrow } from "./MultiCarouselRightArrow"

interface ShopDetailsImageCarouselProps {
  img: string[]
}

export default function ShopDetailsImageCarousel({ img }: ShopDetailsImageCarouselProps) {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  }

  return img.length > 0 ? (
    <Carousel
      responsive={responsive}
      customLeftArrow={<MultiCarouselLeftArrow />}
      customRightArrow={<MultiCarouselRightArrow />}
    >
      {img.map((imgSrc, index) => (
        <div key={index}>
          <img src={imgSrc} className="shop-details-carousel-img object-fit-cover p-2" alt="Immagine negozio" />
        </div>
      ))}
    </Carousel>
  ) : null
}
