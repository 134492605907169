import { useParams } from "react-router-dom"
import Footer from "../components/common/Footer"
import HeaderLogo from "../components/headers/HeaderLogo"
import { useEffect, useState } from "react"
import { getShopById } from "../api/shopsApi"
import { GetShopByIdResultDto } from "../models/api/shopApiTypes"
import ShopDetails from "../components/shop/ShopDetails"
import "../assets/css/shopdetails.css"

export default function ShopPage() {
  const { shopSlug } = useParams()
  const [shop, setShop] = useState<GetShopByIdResultDto>()

  useEffect(() => {
    if (shopSlug !== undefined)
      getShopById(shopSlug)
        .then((result) => {
          setShop(result)
        })
        .catch(() => console.log("Error loading shop"))
  }, [shopSlug])

  return (
    <>
      <HeaderLogo />
      {shop !== undefined ? <ShopDetails shop={shop} /> : null}
      <Footer />
    </>
  )
}
